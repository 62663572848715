<template>
  <v-file-input v-bind="$attrs" v-model="fichero" :error-messages="mensajeError" @change="procesarImagen" />
</template>

<script>
export default {
  props: {
    bytesMaximo: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      fichero: null,
      mensajeError: null,
    };
  },
  methods: {
    createBase64Image (fileObject) {
        let imagenBase64;
        if (fileObject) {
            const reader = new FileReader();
            reader.onload = (e) => {
              imagenBase64 = e.target.result;
              this.mensajeError = null;
              this.$emit('input', imagenBase64);
              // if (e.total <= this.bytesMaximo) {
              //   imagenBase64 = e.target.result;
              //   this.mensajeError = null;
              //   this.$emit('input', imagenBase64);
              // } else {
              //   this.mensajeError = 'El tamaño de la imagen excede el máximo permitido';
              //   this.$emit('error', this.mensajeError);
              // }
            };
            reader.readAsDataURL(fileObject);
        } else {
          this.$emit('input', null);
        }
    },
    procesarImagen() {
      if (this.fichero != null)
        this.createBase64Image (this.fichero);
      else {
        this.mensajeError = null;
        this.$emit('input', null);
      }
    },
  },
};
</script>

<style>
</style>