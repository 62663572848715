<template>
  <v-app style="background: #eeeeee" v-if="cargandoTodosLosDatos">
    <!---Reservas-->
    <div class="box" v-show="reservas">
      <v-container fluid>
        <v-dialog v-model="dialogoNuevaReserva" width="400">
          <v-card class="mt-5 rounded-md" elevation="0">
            <v-card-title>{{ $t("reservaplaza") }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="auto">
                  <h4>{{ $t("fechaEntrada") }}</h4>
                  <SelectorFechaHora
                    :fechasNoPermitidas="fechasNoPermitidas"
                    :fechaMinima="fechaMinima"
                    :fechaMaxima="fechaMaximaReservas"
                    :required="true"
                    :monday-first="true"
                    v-model="fechaEntrada"
                    class="form-control_"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="auto">
                  <h4>{{ $t("fechaSalida") }}</h4>
                  <SelectorFechaHorav2
                    :fechaMinima="fechaMinima"
                    :fechasNoPermitidas="fechasNoPermitidas"
                    :fechaMaxima="fechaMaxima"
                    :permitir="permitirReserva"
                    :required="true"
                    :monday-first="true"
                    v-model="fechaSalida"
                    class="form-control_"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="auto">
                  <h4>{{ $t("zonaAparcamiento") }}</h4>
                  <v-text-field
                    v-if="zonasDisponibles.length <= 1"
                    dense
                    v-model="zonaSeleccionada"
                    prepend-icon="mdi-map-clock"
                    readonly
                  ></v-text-field>
                  <v-select
                    v-else
                    dense
                    :items="zonasDisponibles"
                    v-model="zonaSeleccionada"
                    prepend-icon="mdi-map-clock"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                outlined
                color="primary"
                :loading="cargandoPreReserva"
                :disabled="!todasLasFechasRellenas"
                class="ml-3 white-text mb-3"
                @click="preReserva()"
              >
                {{ $t("btnReservar") }}</v-btn
              >

              <v-btn
                outlined
                dark
                color="red"
                class="ml-3 mb-3 white--text text--accent-4"
                @click="cerrarDialogoNuevaReserva"
              >
                {{ $t("btnCerrar") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- MIS RESERVAS -->
        <v-dialog v-model="dialogoCerrarSesion" v-if="dialogoCerrarSesion" max-width="490">
          <v-card>
            <v-card-title class="justify-center">{{$t('tituloCerrarSesion')}}</v-card-title>
            <v-card-text class="justify-center">
              <v-row>
                <v-col cols="6" >
                  <v-btn large block  outlined color="red" @click="cerrarSesion()">{{$t('si')}}</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn large block outlined color="black" @click="dialogoCerrarSesion = false" >{{$t('no')}}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-card class="mb-15" color="#eeeeee" elevation="0">
          <v-row justify="center" align="center">

            <v-card-title>
              {{ $t("pasetituloQR") }}
            </v-card-title>

          </v-row>
          <v-expand-transition>
            <v-row justify="center" align="center" class="mb-5" v-if="qrActivo">
              <v-card color="transparent"  elevation="12">
              <qr-code :text="passGuid"  class="pa-2" ></qr-code>
              </v-card>
            </v-row>
          </v-expand-transition>

            <v-card-text>
              <v-row justify="center" align="center">
                <v-avatar
                  style="height: 35px; min-width: 35px; width: 35px"
                  me-3
                  color="orange"
                  rounded
                >
                  <v-icon v-if="bn_reservas" color="white"
                    >mdi-calendar-check</v-icon
                  >
                  <v-icon v-else-if="bn_cesiones" color="white"
                    >mdi-rotate-3d-variant</v-icon
                  >
                </v-avatar>
                <div class="ml-2">
                  <h3 class="text-xl font-weight-medium mb-n1" v-if="bn_cesiones">
                    {{ cesionesEnCurso }}
                  </h3>
                  <h3
                    class="text-xl font-weight-medium mb-n1"
                    v-else-if="bn_reservas"
                  >
                    {{ reservasEnCurso }}
                  </h3>
                  <span v-if="bn_cesiones">{{$t('cesionesActivas')}}</span>
                  <span v-else-if="bn_reservas">{{$t('reservasActivas')}}</span>
                </div>
              </v-row>
            </v-card-text>
          <v-card-title
            >{{ $t("misReservas") }}

            <v-btn
              small
              color="primary"
              dark
              absolute
              right
              fab
              @click="dialogoNuevaReserva = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="hayReservasActivas">
            <v-card
              elevation="10"
              v-for="reserva in reservasActivas"
              :key="reserva['locator']"
              class="px-3 py-3 mt-2"
            >
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-avatar tile color="primary">
                    <div>
                      <p
                        class="font-weight-medium mb-0"
                        style="color: white; font-family: Inter, sans-serif"
                      >
                        {{ obtenerMes(reserva["activation"]) }}
                      </p>
                      <p
                        class="text-xl white--text font-weight-bold mb-0"
                        style="font-family: Inter, sans-serif; font-size: 20px"
                      >
                        {{ obtenerDia(reserva["activation"]) }}
                      </p>
                    </div>
                  </v-avatar>
                </v-col>
                <v-col v-show="esElMismoDia(reserva)" class="ml-2" cols="auto">
                  <v-avatar tile color="primary">
                    <div>
                      <p
                        class="font-weight-medium mb-0"
                        style="color: white; font-family: Inter, sans-serif"
                      >
                        {{ obtenerMes(reserva["expiry"]) }}
                      </p>
                      <p
                        class="text-xl white--text font-weight-bold mb-0"
                        style="font-family: Inter, sans-serif; font-size: 20px"
                      >
                        {{ obtenerDia(reserva["expiry"]) }}
                      </p>
                    </div>
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="ms-2">
                  <p class="text-base font-weight-semibold text--primary mb-0">
                    {{$t('localizador')}}
                  </p>
                  <span class="text-xs">{{ reserva["locator"] }}</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="ms-2">
                  <v-avatar tile color="black" v-if="reserva['assigned_place']">
                    <div >
                      <!-- <p
                        class="font-weight-medium mb-0"
                        style="color: white; font-family: Inter, sans-serif"
                      >
                        PLAZA
                      </p> -->
                      <p
                        class="text-xl white--text font-weight-bold mb-0"
                        style="font-family: Inter, sans-serif; font-size: 20px"
                      >
                        {{ reserva["assigned_place"] }}
                      </p>
                    </div>
                  </v-avatar>
                </v-col>
              </v-row>
              <v-divider class="mt-4"></v-divider>
              <v-row no-gutters class="mt-2">
                <v-col cols="auto">
                  <v-icon> mdi-map-marker</v-icon>
                </v-col>
                <v-col cols="auto" class="mt-1 ml-1">
                  <p class="text-base font-weight-bold text--primary mb-1">
                    {{ reserva["zone_description"] }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2">
                <v-col cols="auto">
                  <v-icon> mdi-clock</v-icon>
                </v-col>
                <v-col cols="auto" class="mt-1 ml-1">
                  <p class="text-base font-weight-bold text--primary">
                    {{ obtenerFechasInicioFinalJuntos(reserva) }}
                  </p>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="auto">
                  <div class="col col-3">
                    <div>
                      <v-btn
                        class="mx-1"
                        fab
                        @click="anularReserva(reserva)"
                        elevation="0"
                        small
                      >
                        <v-icon> mdi-delete-outline </v-icon>
                      </v-btn>
                    </div>
                    <span class="text-no-wrap text--primary font-weight-bold">
                      {{$t('eliminar')}}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-text v-else>
            <v-card-subtitle>{{$t('sinReservas')}}</v-card-subtitle>
          </v-card-text>
        </v-card>
      </v-container>
    </div>

    <!--- Apartado Cesiones-->

    <div class="box" v-show="cesiones">
      <v-container fluid>
        <v-card class="mb-15" color="#eeeeee" elevation="0">
                    <v-row justify="center" align="center">

            <v-card-title>
              {{ $t("pasetituloQR") }}
            </v-card-title>

          </v-row>
          <v-expand-transition>
            <v-row justify="center" align="center" class="mb-5" v-if="qrActivo">
              <v-card color="transparent"  elevation="12">
              <qr-code :text="passGuid"  class="pa-2" ></qr-code>
              </v-card>
            </v-row>
          </v-expand-transition>

            <v-card-text>
              <v-row justify="center" align="center">
                <v-avatar
                  style="height: 35px; min-width: 35px; width: 35px"
                  me-3
                  color="orange"
                  rounded
                >
                  <v-icon v-if="bn_reservas" color="white"
                    >mdi-calendar-check</v-icon
                  >
                  <v-icon v-else-if="bn_cesiones" color="white"
                    >mdi-rotate-3d-variant</v-icon
                  >
                </v-avatar>
                <div class="ml-2">
                  <h3 class="text-xl font-weight-medium mb-n1" v-if="bn_cesiones">
                    {{ cesionesEnCurso }}
                  </h3>
                  <h3
                    class="text-xl font-weight-medium mb-n1"
                    v-else-if="bn_reservas"
                  >
                    {{ reservasEnCurso }}
                  </h3>
                  <span v-if="bn_cesiones">{{$t('cesionesActivas')}}</span>
                  <span v-else-if="bn_reservas">{{$t('reservasActivas')}}</span>
                </div>
              </v-row>
            </v-card-text>
          <v-card-title
            >{{ $t("tituloCederPlaza") }}

            <v-btn
              small
              color="#006064"
              dark
              absolute
              right
              fab
              @click="dialogoNuevaCesion = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="itemsCesiones.length > 0">
            <v-card
              elevation="10"
              v-for="cesion in itemsCesiones"
              :key="cesion['id']"
              class="px-3 py-3 mt-4"
            >
              <v-row no-gutters>
                <v-col cols="auto">
                  <v-avatar tile color="#006064">
                    <div>
                      <p
                        class="font-weight-medium mb-0"
                        style="color: white; font-family: Inter, sans-serif"
                      >
                        {{ obtenerMes(cesion["activation"]) }}
                      </p>
                      <p
                        class="text-xl white--text font-weight-bold mb-0"
                        style="font-family: Inter, sans-serif; font-size: 20px"
                      >
                        {{ obtenerDia(cesion["activation"]) }}
                      </p>
                    </div>
                  </v-avatar>
                </v-col>
                <v-col v-show="esElMismoDia(cesion)" class="ml-2" cols="auto">
                  <v-avatar tile color="#006064">
                    <div>
                      <p
                        class="font-weight-medium mb-0"
                        style="color: white; font-family: Inter, sans-serif"
                      >
                        {{ obtenerMes(cesion["expiry"]) }}
                      </p>
                      <p
                        class="text-xl white--text font-weight-bold mb-0"
                        style="font-family: Inter, sans-serif; font-size: 20px"
                      >
                        {{ obtenerDia(cesion["expiry"]) }}
                      </p>
                    </div>
                  </v-avatar>
                </v-col>
                <v-col cols="auto" class="ms-2">
                  <p class="text-base font-weight-semibold text--primary mb-0">
                    {{$t('plazaCedida')}}
                  </p>
                  <span class="text-xs">{{ cesion["place_number"] }}</span>
                </v-col>
              </v-row>
              <v-divider class="mt-4"></v-divider>
              <v-row no-gutters class="mt-2">
                <v-col cols="auto">
                  <v-icon> mdi-map-marker</v-icon>
                </v-col>
                <v-col cols="auto" class="mt-1 ml-1">
                  <p class="text-base font-weight-bold text--primary mb-1">
                    {{ cesion["zone_description"] }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-2">
                <v-col cols="auto">
                  <v-icon> mdi-clock</v-icon>
                </v-col>
                <v-col cols="auto" class="mt-1 ml-1">
                  <p class="text-base font-weight-bold text--primary">
                    {{ obtenerFechasInicioFinalJuntos(cesion) }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="">
                <v-col cols="auto">
                  <v-icon> mdi-message-bulleted</v-icon>
                </v-col>
                <v-col cols="auto" class="mt-1 ml-1">
                  <p class="text-base font-weight-bold text--primary">
                    {{ cesion["remarks"] }}
                  </p>
                </v-col>
              </v-row>
            </v-card>
          </v-card-text>
          <v-card-text v-else>
            <v-card-subtitle>{{$t('sinCesiones')}}</v-card-subtitle>
          </v-card-text>
        </v-card>

        <v-dialog v-model="dialogoNuevaCesion">
          <v-card class="mt-5 rounded-md" elevation="0">
            <v-card-title>{{ $t("tituloCederPlaza") }} </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="auto">
                  <h4>{{ $t("fechaInicioCesion") }}</h4>
                  <SelectorFechaHora
                    :fechasNoPermitidas="fechasNoPermitidasCesiones"
                    :fechaMinima="fechaMinimaCesiones"
                    :required="true"
                    :monday-first="true"
                    v-model="fechaInicioCesion"
                    class="form-control_"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="auto">
                  <h4>{{ $t("fechaFinalCesion") }}</h4>
                  <SelectorFechaHorav2
                    :fechasNoPermitidas="fechasNoPermitidasCesiones"
                    :permitir="permitirCesion"
                    :fechaMinima="fechaMinimaCesiones"
                    :required="true"
                    :monday-first="true"
                    v-model="fechaFinalCesion"
                    class="form-control_"
                  />
                </v-col>
              </v-row>
              <v-row no-gutters>
                <!-- <v-col cols="auto">
                  <h4>{{ $t("zonaAparcamiento") }}</h4>
                  <v-text-field
                    v-if="zonasDisponibles.length <= 1"
                    dense
                    v-model="zonaSeleccionada"
                    prepend-icon="mdi-map-clock"
                    readonly
                  ></v-text-field>
                  <v-select
                    v-else
                    dense
                    :items="zonasDisponibles"
                    v-model="zonaSeleccionada"
                    prepend-icon="mdi-map-clock"
                  ></v-select>
                </v-col> -->
                <!-- <v-col cols="auto">
                  <h4>{{ $t("plazaAsignada") }}</h4>
                  <v-text-field
                    v-if="plaza"
                    dense
                    v-model="plaza"
                    prepend-icon="mdi-map-marker"
                    readonly
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12">
                  <v-textarea
                    :label="$t('motivo')"
                    v-model="motivoCesion"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn
                outlined
                :disabled="!todosRellenos"
                color="#006064"
                @click="cederPlaza()"
              >
                {{ $t("btnCeder") }}</v-btn
              >

              <v-btn
                outlined
                color="red"
                class="ml-3 white--text"
                @click="dialogoNuevaCesion = false"
              >
                {{ $t("btnCerrar") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>
        <div style="padding-bottom: 30px"></div>
      </v-container>
    </div>

    <!--- Apartado Usuario-->
    <div class="box" v-show="cuenta">
      <file-input-base-64
        ref="fileinput"
        v-show="false"
        accept="image/*"
        show-size
        small-chips
        label="Selecciona un avatar"
        v-model="imagenBase64"
        @error="errorImagenPerfil"
      />
      <v-container fluid>
        <v-card class="mt-2" elevation="0">
          <v-card-text>
            <h2 class=" text-xl font-weight-semibold mb-2">{{$t('perfil')}}</h2>
            <v-divider></v-divider>
          </v-card-text>
          <v-row justify="center" align="center">
            <v-card-title>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    style="cursor: pointer;"
                    class="profile"
                    size="200"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-img v-if="imagenBase64 !== null "  @click="elegirFichero"
                        :src="imagenBase64"  >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="black lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-img v-else @click="elegirFichero"
                        src="@/assets/avatar.png"  >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="black lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>

                  </v-avatar>
                </template>
                <span style="font-weight: 900;">Cambiar foto de perfil</span>
              </v-tooltip>
            </v-card-title>
          </v-row>
          <v-row class="mb-2" justify="center" align="center">
            <span>{{ nombreCompleto }}</span>
          </v-row>
          <v-row class="mb-2" justify="center" align="center">
            <span style="font-weight: 900;font-size: larger;">{{
              empresaInquilina
            }}</span>
          </v-row>

          <v-card-text>
            <h2 class="mt-4 text-xl font-weight-semibold mb-2">{{$t('detalles')}}</h2>
            <v-divider></v-divider>
            <div role="list" class="v-list v-sheet">
              <div
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium me-2">{{ $t("correov2") }}:</span
                ><span class="text--secondary">{{ email }}</span>
              </div>
              <div
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium text-no-wrap me-2">{{
                  $t("informacionPaseTitulo")
                }}</span
                ><span class="text--secondary">{{ tipoPase }}</span>
              </div>
              <div
                v-if="matriculasPase.length > 0"
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium text-no-wrap me-2">{{
                  $t("matriculasTitulo")
                }}</span>
                <v-row no-gutters v-if="matriculasPase.length > 0">
                  <v-col cols="auto">
                    <v-chip
                      color="#FFF3E0"
                      small
                      show-arrows
                      class="orange--text text--accent-4"
                      v-for="matricula in matriculasPase"
                      :key="matricula"
                      :value="matricula"
                    >
                      {{ matricula }}
                    </v-chip>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium text-no-wrap me-2">{{
                  $t("matriculasTitulo")
                }}</span
                ><span class="text--secondary">{{ $t("sinMatriculas") }}</span>
              </div>
              <div
                v-if="contactless.length > 0"
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium text-no-wrap me-2">{{
                  $t("contactlessTitulo")
                }}</span>
                <v-row v-if="contactless.length > 0" no-gutters>
                  <v-col cols="auto">
                    <v-chip-group class="ml-3">
                      <v-chip
                        color="#FFF3E0"
                        class="orange--text text--accent-4"
                        small
                        show-arrows
                        v-for="contact in contactless"
                        :key="contact"
                        :value="contact"
                      >
                        {{ contact }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
              </div>
              <div
                v-else
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium text-no-wrap me-2">{{
                  $t("contactlessTitulo")
                }}</span
                ><span class="text--secondary">{{ $t("sinContactless") }}</span>
              </div>
              <div
                v-if="bn_cesiones"
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium text-no-wrap me-2">{{
                  $t("zonaPlaza")
                }}</span
                ><span class="text--secondary">{{ zonaSeleccionada }}</span>
              </div>
              <div
                v-if="bn_cesiones"
                tabindex="-1"
                role="listitem"
                class="px-0 mb-n2 v-list-item v-list-item--dense"
              >
                <span class="font-weight-medium me-2">{{ $t("miPlaza") }}:</span
                ><span class="text--secondary">{{ miPlaza }}</span>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center" align="center" class="mb-3">
              <v-col cols="12" >
                <v-btn
                  block
                  outlined
                  color="orange"
                  x-large
                  @click="cuadroDialogoEditarUsuario = true"
                >
                  <v-icon class="mr-1">mdi-pencil</v-icon>
                  {{ $t("tituloEditarUsuario") }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  x-large
                  @click="dialogoCerrarSesion = true"
                  color="red"
                  dark
                  outlined
                >
                  <v-icon class="mr-1">mdi-logout</v-icon>
                  {{ $t("btnCerrarSesion") }}
                </v-btn>
              </v-col>
              <span style="font-weight: 400;font-size: small;">{{ $estadoApp.version }}</span>
            </v-row>

          </v-card-actions>
          <v-fab-transition>
            <v-btn
              fab
              small
              fixed
              dark
              v-show="editarImagen"
              top
              right
              :loading="guardandoImagen"
              color="orange"
              @click="guardarNuevaImagen"
              class="mx-2"
            >
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-card>
        <div style="margin-bottom:60px"></div>
      </v-container>
    </div>

    <v-bottom-navigation
      mandatory
      fixed
      grow

      v-model="valor"
      app
      height="65"
      background-color="#eeeeee"

      shift
    >
      <v-btn
        ref="reservas"
        value="reservas"
        class="pb-4"
        v-show="bn_reservas"
        @click="ver('reservas')"
      >
        <span >{{ $t("inicio") }}</span>

        <v-icon >mdi-home-variant-outline</v-icon>
      </v-btn>
      <v-btn
        class="pb-4"
        value="cesiones"
        v-show="bn_cesiones"
        @click="ver('cesiones')"
      >
        <span>{{ $t("inicio") }}</span>

        <v-icon >mdi-home-variant-outline</v-icon>
      </v-btn>
      <v-btn
        class="pb-4"
        value="cuenta"
        v-show="bn_cuenta"
        @click="ver('cuenta')"
      >
        <span >{{ $t("botnavUsuario") }}</span>
        <v-icon  >mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!--- CUADRO DE DIALOGO PARA CONFIRMAR LA RESERVA --->

    <v-dialog v-model="cuadroDialogoConfirmarReserva" persistent max-width="690">
      <v-card class="mt-4">
        <v-card-title class="mt-3">{{ $t("confirmarReserva") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="auto">
              <span>{{
                $t("confirmarReservapt1") +
                fechaEntradaModificada +
                $t("confirmarReservapt2") +
                fechaSalidaModificada +
                $t("confirmarReservapt3")
              }}</span>
            </v-col>
          </v-row>
          <v-row v-if="opcionesPlazasReservables.length > 0">
            <v-col cols="auto">
              <h4>{{ $t("eligePlaza") }}</h4>
              <v-select
                prepend-icon="mdi-ticket-confirmation"
                dense
                v-model="plazaSeleccionada"
                :items="opcionesPlazasReservables"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            dark
            color="primary"
            class="ml-3 mb-3 text--accent-4"
            @click="confirmarCita"
            :loading="cargandoConfirmarPreReserva"
          >
            {{ $t("btnConfirmar") }}
          </v-btn>
          <v-btn
            outlined
            dark
            color="red"
            class="ml-3 mb-3 white--text text--accent-4"
            @click="cuadroDialogoConfirmarReserva = false"
          >
            {{ $t("btnCerrar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CUADRO DE DIALOGO PARA EDITAR USUARIO - -->

    <v-dialog v-model="cuadroDialogoEditarUsuario" >
      <v-card class="mt-4">
        <v-card-title>{{ $t("tituloEditarUsuario") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-row>
            <!-- <v-col cols="auto">
              <h4>{{ $t("idiomaEditarUsuario") }}</h4>
              <v-select
                dense
                v-model="idiomaSeleccionado"
                :items="idiomasDisponibles"
                :prepend-icon="iconoSelect"
                item-text="nom"
              >
                <template v-slot:item="slotProps">
                  <i :class="['mr-3', 'em', slotProps.item.ico]"></i>
                  {{ slotProps.item.nom }}
                </template>
              </v-select>
            </v-col> -->
            <v-col cols="12">
              <h4>{{ $t("contrasena") }}</h4>
              <v-text-field
                dense
                :rules="[rules.required]"
                :type="mostrarPassword ? 'text' : 'password'"
                spellcheck="false"
                :append-icon="mostrarPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="mostrarPassword = !mostrarPassword"
                v-model="nuevaContrasena"
                prepend-icon="mdi-lock"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4>{{ $t("contrasenaRepetir") }}</h4>
              <v-text-field
                dense
                :rules="[rulesNuevaContrasena.required]"
                :type="mostrarPasswordConfirm ? 'text' : 'password'"
                spellcheck="false"
                :append-icon="
                  mostrarPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="mostrarPasswordConfirm = !mostrarPasswordConfirm"
                v-model="confirmarContrasena"
                prepend-icon="mdi-lock-reset"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            outlined
            :disabled="!ambasContraseñasIguales"
            class="ml-3 mb-3"
            color="primary"
            @click="guardarEditarUsuario"
            >{{ $t("btnGuardar") }}</v-btn
          >
          <v-btn
            outlined
            class="ml-3 mb-3 text--accent-4"
            color="red"
            @click="cerrarEdicionUsuario"
          >
            {{ $t("btnCerrar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import api from "../api";
import i18n from "@/plugins/i18n";
import SelectorFechaHora from "../components/SelectorFechaHora.vue";
import SelectorFechaHorav2 from "../components/SelectorFechaHorav2.vue";
import FileInputBase64 from "../components/FileInputBase64.vue";
import { DateTime, Settings } from "luxon";
import appLocalStorage from "../appLocalStorage";
import { Preferences } from '@capacitor/preferences';
import LoginVue from '../components/Login.vue';


export default {
  components: {
    SelectorFechaHora,
    SelectorFechaHorav2,
    FileInputBase64
  },

  data() {
    return {
      rules: {
        required: (value) => !!value || this.$t('Requerido'),
        required: (value) =>
          value.length > 8 || this.$t('requerimientosCaracteres'),
      },
      rulesNuevaContrasena: {
        required: (value) => !!value || this.$t('Requerido'),
        required: (value) =>
          value.length > 8 || this.$t('requerimientosCaracteres'),
        required: (value) =>
          value == this.nuevaContrasena || this.$t('requerimientoCoincidencias')
      },
      miPlaza: "",
      qrActivo:false,
      guardandoImagen: false,
      imagenBase64Anterior: "",
      imagenBase64: "",
      reservasEnCurso: 0,
      cesionesEnCurso: 0,
      fechaMaximaReservas: "",
      dialogoNuevaCesion: false,
      dialogoNuevaReserva: false,
      permitirReserva: false,
      permitirCesion: false,
      fechaMinimaSalidaReserva: "",
      minimosDiasEntreReservas: "",
      fechaMaxima: "",
      fechasNoPermitidasCesiones: [],
      fechasNoPermitidas: [],
      fechaMinimaCesiones: "",
      valor: "cuenta",
      cargandoTodosLosDatos: false,
      fechaPermitirdaEntrada: "",
      requisitos: [],
      valorPorDefecto: 1,
      colorFondo: "#E65100",
      noModificado: true,
      confirmarContrasena: "",
      nuevaContrasena: "",
      mostrarPasswordConfirm: false,
      mostrarPassword: false,
      nombreCompleto: "",
      fechaInicioCesion: "",
      fechaFinalCesion: "",
      fechaEntrada: "",
      fechaEntradaModificada: "",
      fechaSalidaModificada: "",
      plaza: "",
      fechaSalida: "",
      bn_reservas: true,
      reservas: false,
      bn_cesiones: false,
      cesiones: false,
      bn_cuenta: false,
      cuadroDialogoEditarUsuario: false,
      passGuid: "",
      cuenta: false,
      empresaInquilina: "",
      cargandoPreReserva: false,
      cargandoConfirmarPreReserva: false,
      email: "",
      tipoPase: "",
      plazaSeleccionada: "",
      alto: "",
      ancho: "",
      matriculasPase: [],
      itemsCesiones: [],
      cuadroDialogoConfirmarReserva: false,
      respuesta: "",
      motivoCesion: "",
      zonaSeleccionada: "",
      zonas: [],
      opcionesPlazasReservables: [],
      contactless: [],
      zonasDisponibles: [],
      idiomaOriginal: "",
      idiomasDisponibles: [],
      idiomaSeleccionado: "",
      fechaMinima: "",
      reservasActivas: [],
      hayReservasActivas: false,
      iconoSelect: "mdi-flag",
      dialogoCerrarSesion:false,
    };
  },

  computed: {
    ambasContraseñasIguales() {
      return (
        this.confirmarContrasena === this.nuevaContrasena &&
        this.confirmarContrasena !== "" &&
        this.nuevaContrasena !== ""
      );
    },
    todasLasFechasRellenas() {

      return (
        this.fechaEntrada !== "" &&
        this.fechaSalida !== ""
      );

      // && this.fechasValidas()

    },
    todosRellenos() {
      return (
        this.fechaInicioCesion !== "" &&
        this.fechaFinalCesion !== "" &&
        this.motivoCesion !== ""
      );
    },
    editarImagen() {
      return this.imagenBase64 !== this.imagenBase64Anterior;
    },
  },
  watch: {
    fechaInicioCesion() {
      let diaActual = DateTime.now();
      diaActual = diaActual.plus({ days: 1 });
      this.fechaMinimaCesiones = diaActual.toFormat("yyyy-MM-dd");
      this.permitirCesion = true;
    },
    fechaEntrada() {
      let fechaSeleccionada = DateTime.fromISO(
        this.fechaEntrada.replace(" ", "T")
      );

      let fechaActual = DateTime.now();

      this.fechaMinima = fechaActual.toFormat("yyyy-MM-dd");

      if (this.minimosDiasEntreReservas !== 1)
        fechaSeleccionada = fechaSeleccionada.plus({
          days: this.minimosDiasEntreReservas - 1,
        });

      //Calculamos la fecha maxima con las condiciones pasadas por el pase

      let fechaMaximaFutura = fechaSeleccionada.plus({
        hours: this.requisitos.company.maximum_hours_reservation,
      });

      fechaMaximaFutura = fechaMaximaFutura.plus({
        days: -1,
      });

      this.fechaMaxima = fechaMaximaFutura.toFormat("yyyy-MM-dd");

      this.permitirReserva = true;
    },
    idiomaSeleccionado() {
      if (this.idiomaSeleccionado == "") {
        this.iconoSelect = "mdi-flag";
      } else {
        this.idiomasDisponibles.forEach((element) => {
          if (element["nom"] == this.idiomaSeleccionado) {
            this.iconoSelect = element["ico"];
          }
        });
      }

      this.comprobarCambiosUsuario();
    },

    confirmarContrasena() {
      this.comprobarCambiosUsuario();
    },
    nuevaContrasena() {
      this.comprobarCambiosUsuario();
    },
  },
  methods: {
    //Guardamos la imagen de usuario
    guardarNuevaImagen() {
      this.guardandoImagen = true;

      api
        .guardarNuevaImagen(this.imagenBase64)
        .then(() => {})
        .finally(() => {
          this.guardandoImagen = false;
          this.imagenBase64Anterior = this.imagenBase64;
        });
    },

    //Se comprueba si las fechas son o no validas
    fechasValidas() {
      if (
        (this.fechaEntrada == "" && this.fechaSalida == "") ||
        this.fechaEntrada == null ||
        this.fechaSalida == null
      ) {
        return false;
      } else {
        let fechaEntrada = this.fechaEntrada.replace(" ", "T");
        let fechaValida = this.fechaPermitirdaEntrada.replace(" ", "T");

        if (fechaValida > fechaEntrada) {
          return false;
        } else {
          return true;
        }
      }
    },

    //Al cerrar el dialogo quitamos los valores seleccionados
    cerrarDialogoNuevaReserva(){
      this.dialogoNuevaReserva = false;
      this.fechaEntrada = '';
      this.fechaSalida = '';
      this.permitirReserva = false;
    },
    //comprobamos si ha cambiado algo del formulario
    comprobarCambiosUsuario() {
      if (
        (this.idiomaSeleccionado !== this.idiomaOriginal &&
          this.confirmarContrasena == this.nuevaContrasena) ||
        (this.confirmarContrasena == this.nuevaContrasena &&
          this.confirmarContrasena != "" &&
          this.nuevaContrasena != "")
      ) {
        this.noModificado = false;
      } else {
        this.noModificado = true;
      }
    },

    //Confirmamos la prereserva
    confirmarCita() {

      this.cargandoConfirmarPreReserva = true;
      let peticion = {};

      peticion.prebooking_guid = this.preBooking_guid;
      peticion.preferred_place = this.plazaSeleccionada;

      api.confirmarPreReserva(peticion).then((res) => {
        if (res.data.respuesta["codigoHTTP"] == 400) {
          this.$cuadroDeDialogo.mostrar(
            this.$t("errorAlSolicitarReservaDialogo"),
            this.$t("errorAlConfirmarReservaDialogopt1"),
            ["Ok"]
          );
        } else {
          this.$cuadroDeDialogo
            .mostrar(
              this.$t("enhorabuenaDialogo"),
              this.$t("reservaConfirmadaDialogo"),
              ["Ok"]
            )
            .then(() => {
              this.colorFondo = "#E65100";
              this.reservas = false;
              this.cesiones = false;
              this.cuenta = true;
              this.fechaSalida = "";
              this.fechaEntrada = "";
              this.plaza = "";
              this.plazaSeleccionada = "";
              this.dialogoNuevaReserva = false;
              this.cargandoTodosLosDatos = false;
              this.$estadoApp.cargandoOverlay = true;
              this.obtenerInformacionEntidad();
            });
        }

        this.cargandoConfirmarPreReserva = false;
        this.cuadroDialogoConfirmarReserva = false;
      });
    },
    //Realizamos la prereserva para verficiar que tiene disponibilidad
    preReserva() {
      //this.cargandoPreReserva = true;
      let id;
      this.zonas.forEach((element) => {
        if (this.zonaSeleccionada == element["description"]) {
          id = element["id"];
        }
      });

      let peticion = {};

      peticion.zone_id = id;
      peticion.pass_guid = this.passGuid;
      //Modificamos las horas
      let fechaEntradaReplace = this.fechaEntrada.replace(" ", "T");
      let fechaEntradaISO = DateTime.fromISO(fechaEntradaReplace);
      this.fechaEntradaModificada =
        fechaEntradaISO.setLocale('es')
        .toFormat("dd-MM-yyyy HH:mm:ss");
      peticion.activation = fechaEntradaReplace;


      let fechaSalidaReplace = this.fechaSalida.replace(" ", "T");
      let fechaSalidaISO = DateTime.fromISO(fechaSalidaReplace);
      this.fechaSalidaModificada =
        fechaSalidaISO.setLocale('es')
        .toFormat("dd-MM-yyyy HH:mm:ss");
      peticion.expiry = fechaSalidaReplace;

      // let fecha = fechaSalidaReplace.split("T");
      // let fechaSalidaFinal = fecha[0] + "T23:59:59";
      let fechaISOSalida = DateTime.fromISO(fechaSalidaReplace);

      //Calculamos las horas entre fechas para ver si tiene permiso para realizar la reserva
      let horas = fechaISOSalida.diff(fechaEntradaISO, "hours").toObject();

      let horasMaximosParaCadaReserva =
        this.requisitos.company.maximum_hours_reservation;
      let horasReserva = Math.round(horas["hours"]);

      //Calculamos las horas minimas antes de reservar
      let fechaActual = DateTime.now();

      let diferenciaHorasMinimaAntesDeReservas = fechaEntradaISO.diff(fechaActual, "hours").toObject();

      // console.log(diferenciaHorasMinimaAntesDeReservas);

      // console.log(this.requisitos.company.minimum_hours_before_reserve);

      if(diferenciaHorasMinimaAntesDeReservas > this.requisitos.company.minimum_hours_before_reserve){
        console.log('No puede reservar');
      }

      if (horasMaximosParaCadaReserva >= horasReserva) {
        //Aqui ya tiene permisos por horas
        api.preReserva(peticion).then((res) => {
          this.cargandoPreReserva = false;
          if (res.data.datos["codigoHTTP"] == 400) {
            if (
              res.data.datos["datos"]["error_message"] ==
                "Expiry date/time not valid" ||
              res.data.datos["datos"]["error_message"] ==
                "Activation date/time not valid"
            ) {
              //Fechas incorrectas
              this.$cuadroDeDialogo.mostrar(
                this.$t("errorAlSolicitarReservaDialogo"),
                this.$t("errorAlSolicitarReservaDialogopt1"),
                ["Ok"]
              );
            } else if (
              res.data.datos["datos"]["error_message"] ==
              "Maximum number of reservations reached"
            ) {
              this.$cuadroDeDialogo.mostrar(
                this.$t("errorAlSolicitarReservaDialogo"),
                this.$t("errorSolicitarReservaMaximoTexto"),
                ["Ok"]
              );
            }else{
              this.$cuadroDeDialogo.mostrar(
                'ERROR',
                res.data.datos["datos"]["error_message"]
              )
            }
          } else if (res.data.datos["codigoHTTP"] == 200) {
            ///Peticion correcta obtenemos los datos

            if (res.data.datos["datos"]["free_reservable_places"] > 0) {
              //Plazas disponibles
              this.opcionesPlazasReservables =
                res.data.datos["datos"]["reservable_places"];
              this.preBooking_guid = res.data.datos["datos"]["prebooking_guid"];
              this.cuadroDialogoConfirmarReserva = true;
            } else {
              //No hay plazas disponibles
              this.$cuadroDeDialogo.mostrar(
                this.$t("sinPlazasDialogo"),
                this.$t("sinPlazasDialogopt1"),
                ["Ok"]
              );
            }
          }
        });
      } else {
        //Se pasa de horas permitidas
        this.$cuadroDeDialogo.mostrar(
          this.$t("errorAlSolicitarReservaDialogo"),
          this.$t("textoPasadoHoras") +
            this.requisitos.company.maximum_hours_reservation +
            this.$t("horas"),
          ["Ok"]
        );
      }
    },

    cederPlaza() {
      if (
        this.fechaInicioCesion !== "" &&
        this.fechaFinalCesion !== "" &&
        this.zonaSeleccionada !== ""
      ) {
        //Obtenemos el id de la zona
        let id;
        this.zonas.forEach((element) => {
          if (this.zonaSeleccionada == element["parking"]) {
            id = element["id"];
          }
        });

        //Obtenemos los datos de la peticion
        let peticion = {};

        peticion.zone_id = id;
        peticion.pass_guid = this.passGuid;

        peticion.remarks = this.motivoCesion;
        //Modificamos las horas
        let fechaEntradaReplace = this.fechaInicioCesion.replace(" ", "T");
        let fechaEntradaISO = DateTime.fromISO(fechaEntradaReplace);
        let fechaEntradaModificada =
          fechaEntradaISO.toFormat("dd-MM-yyyy") + " 00:00:00";
        peticion.activation =
          fechaEntradaISO.toFormat("yyyy-MM-dd") + "T00:00:00";

        let fechaSalidaReplace = this.fechaFinalCesion.replace(" ", "T");
        let fechaSalidaISO = DateTime.fromISO(fechaSalidaReplace);
        let fechaSalidaModificada =
          fechaSalidaISO.toFormat("dd-MM-yyyy") + " 23:59:59";
        peticion.expiry = fechaSalidaISO.toFormat("yyyy-MM-dd") + "T23:59:59";

        this.$cuadroDeDialogo
          .mostrar(
            this.$t("cesionPlazaDialogo"),
            this.$t("cesionPlazaDialogopt1") +
              fechaEntradaModificada +
              this.$t("cesionPlazaDialogopt2") +
              fechaSalidaModificada +
              "?",
            [this.$t("si"), this.$t("no")]
          )
          .then((res) => {
            if (res.texto == this.$t("si")) {
              api.cederPlaza(peticion).then((res) => {
                if (res.data.cesion["codigoHTTP"] == 400) {
                  if (
                    res.data.cesion["datos"]["error_message"] ==
                      "Expiry date/time not valid" ||
                    res.data.cesion["datos"]["error_message"] ==
                      "Activation date/time not valid"
                  ) {
                    //Fechas incorrectas
                    this.$cuadroDeDialogo.mostrar(
                      this.$t("errorAlSolicitarCesionDialogo"),
                      this.$t("errorAlSolicitarCesionDialogopt1"),
                      ["Ok"]
                    );
                  }
                } else if (res.data.cesion["codigoHTTP"] == 200) {
                  ///Peticion correcta obtenemos los datos

                  this.$cuadroDeDialogo
                    .mostrar(
                      this.$t("enhorabuenaDialogoCesion"),
                      this.$t("cesionCompletadaConExitoDialogo"),
                      ["Ok"]
                    )
                    .then(() => {
                      this.motivoCesion = "";
                      this.colorFondo = "#E65100";
                      this.reservas = false;
                      this.cesiones = false;
                      this.cuenta = true;
                      this.dialogoNuevaCesion = false;
                      this.fechaInicioCesion = "";
                      this.fechaFinalCesion = "";
                      this.cargandoTodosLosDatos = false;
                      this.$estadoApp.cargandoOverlay = true;
                      this.obtenerInformacionEntidad();
                    });
                }
              });
            }
          });
      } else {
        this.$cuadroDeDialogo.mostrar(
          this.$t("datosIncompletosDialogo"),
          this.$t("datosIncompletosDialogop2"),
          ["Ok"]
        );
      }
    },

    ver(pagina) {
      if (pagina == "reservas") {
        this.colorFondo = "primary";
        this.cesiones = false;
        this.cuenta = false;
        this.reservas = true;
      } else if (pagina == "cesiones") {
        this.colorFondo = "#006064";
        this.reservas = false;
        this.cuenta = false;
        this.cesiones = true;
      } else {
        this.colorFondo = "#E65100";
        this.reservas = false;
        this.cesiones = false;
        this.cuenta = true;
        this.obtenerInformacionEntidadUsuario();
      }
    },

    obtenerInformacionEntidadUsuario(){
      api
        .obtenerInformacionEntidadMenu()
        .then((res) => {
          this.$estadoApp.cargandoOverlay = true;

          this.imagenBase64 = res.data.imgUsu;
          this.imagenBase64Anterior = res.data.imgUsu;
          this.requisitos = res.data.datosEntidad.datos;


          //Obtenemos los datos del usuario
          this.nombreCompleto = res.data.datosEntidad.datos.entity.title;
          this.email = res.data.datosEntidad.datos.entity.email;
          this.empresaInquilina = res.data.datosEntidad.datos.company.name;
          this.tipoPase = res.data.datosEntidad.datos.pass.type;
          this.matriculasPase = res.data.datosEntidad.datos.pass.license_plates;
          this.contactless = res.data.datosEntidad.datos.pass.contactless_cards;



          this.contactless = this.contactless.concat(
            res.data.datosEntidad.datos.pass.other_contactless_ids
          );



          this.zonas = res.data.datosEntidad.datos.destination_zones;



          //Comprobamos las zonas


          this.zonas.forEach((element) => {
            this.zonasDisponibles.push(element["description"]);
          });

          this.zonaSeleccionada = this.zonas[0]["description"];


          if (res.data.datosEntidad.datos.active_reservations){
            this.reservasActivas = [];
            this.reservasActivas =
            res.data.datosEntidad.datos.active_reservations.reverse();
            this.hayReservasActivas = true;
          }
          else
            this.hayReservasActivas = false;

          this.passGuid = res.data.datosEntidad.datos.pass.guid;

          //Permiso para reservas
          if (res.data.datosEntidad.datos.pass_type.booking_required) {
            // this.ver("reservas");
            // this.bn_reservas = true;
            // this.bn_cuenta = true;
            // this.valor = "reservas";
            this.minimosDiasEntreReservas =
              this.requisitos.company.minimum_days_between_reservations;

            //Calculamos la fecha maxima indicada en los parametros recibidos
            let diasMaximosParaReservar =
              this.requisitos.company.maximum_days_before_reserve;

            let fechaActual = DateTime.now();

            let fechaMaxima = fechaActual.plus({
              days: diasMaximosParaReservar,
            });

            this.fechaMaximaReservas = fechaMaxima.toFormat("yyyy-MM-dd");

            if (
              this.requisitos.active_reservations ||
              this.requisitos.last_reservation
            ) {
              this.obtenerFechaEntradaValidaSegunCriteriosEntidad();
            } else {
              let diaActual = DateTime.now();
              diaActual = diaActual.plus({ days: 1 });
              this.fechaMinima = diaActual.toFormat("yyyy-MM-dd");
            }
          } else {
            this.bn_reservas = false;
          }

          //Permiso para ceder
          if (res.data.datosEntidad.datos.pass_type.place_release_allowed) {
            this.miPlaza =
              res.data.datosEntidad.datos.pass.parking_place_number;
            // this.bn_cesiones = true;
            // this.bn_cuenta = true;
            // this.ver("cesiones");
            // this.valor = "cesiones";
            this.plaza = res.data.datosEntidad.datos.pass.parking_place_number;
            this.obtenerFechaInicioValidaSegunCesionesAnteriores();
          } else {
            this.bn_cesiones = false;
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.cargandoTodosLosDatos = true;
          this.$estadoApp.cargandoOverlay = false;
        });
    },

    guardarEditarUsuario() {
      // let id;
      // let abr;
      //Obtenemos el id del idioma
      // this.idiomasDisponibles.forEach((element) => {
      //   if (this.idiomaSeleccionado == element["nom"]) {
      //     id = element["id_idi"];
      //     abr = element["abr"];
      //   }
      // });

      let peticion = {};

      // peticion.id_idi = id;
      if (this.nuevaContrasena !== "") peticion.hascon = this.nuevaContrasena;

      api.editarOperador(peticion).then(() => {
        // this.cambiarIdioma(abr);

        this.nuevaContrasena = "";
        this.confirmarContrasena = "";
        this.cuadroDialogoEditarUsuario = false;
      });
    },

    cerrarEdicionUsuario() {
      this.idiomaSeleccionado = this.iidiomaOriginal;
      this.nuevaContrasena = "";
      this.confirmarContrasena = "";
      this.cuadroDialogoEditarUsuario = false;
    },

    cambiarIdioma: function (idioma) {
      i18n.locale = idioma;
    }, // fin metodo cambiarIdioma

    //Cerramos sesion
    cerrarSesion() {
      Preferences.remove({key:'accessToken'});
      Preferences.remove({key:'correo'});
      appLocalStorage.remove(["accessToken"]);
      appLocalStorage.remove(["correo"]);
      this.$router.push("/");
    },

    //Click del btn para editar el usario
    editarUsuario() {
      api.obtenerIdiomas().then((res) => {
        this.idiomaSeleccionado = res.data.idiomaUsuario[0].nom;
        this.idiomaOriginal = res.data.idiomaUsuario[0].nom;
        this.idiomasDisponibles = res.data.idiomas;
      });
    },

    //Anulamos la reserva
    anularReserva(item) {
      this.$cuadroDeDialogo
        .mostrar(
          this.$t('anularReserva'),
          this.$t('anularReserva2') +
            item["locator"] +
            "?",
          [this.$t("si"), this.$t("no")]
        )
        .then((res) => {
          if (res.texto == this.$t("si")) {
            api.eliminarReserva(item["locator"]).then(() => {


              //Obtenemos los dias que vamos a cancelar para quitarlos de fechas no permitidas
              let partesFechaActivacion = item.activation.split(' ');
              let partesFechaExpiracion = item.expiry.split(' ');

              if(this.fechasNoPermitidas.indexOf(partesFechaActivacion[0]) !== -1){
                this.fechasNoPermitidas.splice(this.fechasNoPermitidas.indexOf(partesFechaActivacion[0]),1)
              }

              if(this.fechasNoPermitidas.indexOf(partesFechaExpiracion[0]) !== -1){
                this.fechasNoPermitidas.splice(this.fechasNoPermitidas.indexOf(partesFechaExpiracion[0]),1)
              }

              // var i = this.reservasActivas.indexOf( item );
              // this.reservasActivas.splice(i,1);

              this.colorFondo = "#E65100";
              this.reservas = false;
              this.cesiones = false;
              this.cuenta = true;
              this.fechaSalida = "";
              this.fechaEntrada = "";
              this.plaza = "";
              this.plazaSeleccionada = "";
              this.dialogoNuevaReserva = false;
              this.cargandoTodosLosDatos = false;
              this.$estadoApp.cargandoOverlay = true;
              this.obtenerInformacionEntidad();
            });
          }
        });
    },
    ///Obtenemos toda la informacion de la entidad
    obtenerInformacionEntidad() {
      api
        .obtenerInformacionEntidadMenu()
        .then((res) => {

          this.$estadoApp.cargandoOverlay = true;
          this.imagenBase64 = res.data.imgUsu;
          this.imagenBase64Anterior = res.data.imgUsu;
          this.requisitos = res.data.datosEntidad.datos;


          //Obtenemos los datos del usuario
          this.nombreCompleto = res.data.datosEntidad.datos.entity.title;
          this.email = res.data.datosEntidad.datos.entity.email;
          this.empresaInquilina = res.data.datosEntidad.datos.company.name;
          this.tipoPase = res.data.datosEntidad.datos.pass.type;
          this.matriculasPase = res.data.datosEntidad.datos.pass.license_plates;
          this.contactless = res.data.datosEntidad.datos.pass.contactless_cards;



          this.contactless = this.contactless.concat(
            res.data.datosEntidad.datos.pass.other_contactless_ids
          );



          this.zonas = res.data.datosEntidad.datos.destination_zones;


          this.zonas.forEach((element) => {
            this.zonasDisponibles.push(element["description"]);
          });

          this.zonaSeleccionada = this.zonas[0]["description"];



          if (res.data.datosEntidad.datos.active_reservations){
            this.reservasActivas = [];
            this.reservasActivas =
            res.data.datosEntidad.datos.active_reservations.reverse();
            this.hayReservasActivas = true;
          }
          else
            this.hayReservasActivas = false;

          this.passGuid = res.data.datosEntidad.datos.pass.guid;

          //Permiso para reservas
          if (res.data.datosEntidad.datos.pass_type.booking_required) {
            this.ver("reservas");
            this.bn_reservas = true;
            this.bn_cuenta = true;
            this.valor = "reservas";
            this.minimosDiasEntreReservas =
              this.requisitos.company.minimum_days_between_reservations;

            //Calculamos la fecha maxima indicada en los parametros recibidos
            let diasMaximosParaReservar =
              this.requisitos.company.maximum_days_before_reserve;

            let fechaActual = DateTime.now();

            let fechaMaxima = fechaActual.plus({
              days: diasMaximosParaReservar,
            });

            this.fechaMaximaReservas = fechaMaxima.toFormat("yyyy-MM-dd");

            if (
              this.requisitos.active_reservations ||
              this.requisitos.last_reservation
            ) {
              this.obtenerFechaEntradaValidaSegunCriteriosEntidad();
            } else {
              let diaActual = DateTime.now();
              diaActual = diaActual.plus({ days: 1 });
              this.fechaMinima = diaActual.toFormat("yyyy-MM-dd");
            }
          } else {
            this.bn_reservas = false;
          }

          //Permiso para ceder
          if (res.data.datosEntidad.datos.pass_type.place_release_allowed) {
            this.miPlaza =
              res.data.datosEntidad.datos.pass.parking_place_number;
            this.bn_cesiones = true;
            this.bn_cuenta = true;
            this.ver("cesiones");
            this.valor = "cesiones";
            this.plaza = res.data.datosEntidad.datos.pass.parking_place_number;
            this.obtenerFechaInicioValidaSegunCesionesAnteriores();
          } else {
            this.bn_cesiones = false;
          }
        })
        .catch((e) => {})
        .finally(() => {
          this.cargandoTodosLosDatos = true;
          this.$estadoApp.cargandoOverlay = false;
        });
    },

    obtenerFechaEntradaValidaSegunCriteriosEntidad() {
      let requisitos = this.requisitos;
      this.fechasNoPermitidas = [];
      //Requisito minimo dias entre reservas
      let minimosDiasEntreReservas =
        requisitos.company.minimum_days_between_reservations;

      this.minimosDiasEntreReservas =
        requisitos.company.minimum_days_between_reservations;
      let diaMasReciente = "";

      if(!requisitos.active_reservations){
        this.reservasEnCurso = 0;
      }else{
        this.reservasEnCurso = requisitos.active_reservations.length;
      }



      //Comprobamos si existe alguna reserva activa y obtenemos el dia mas reciente
      if (requisitos.active_reservations) {


        requisitos.active_reservations.forEach((element) => {
          //Vamos a añadir las fecha no permitidas en un array para que en el calendario no deje mostrarse
          //Obtenemos la fecha de inicio y final para calcular los dias de diferencia
          let fechaInicio = DateTime.fromISO(
            element["activation"].replace(" ", "T")
          );
          let fechaFinal = DateTime.fromISO(
            element["expiry"].replace(" ", "T")
          );

          if (fechaFinal.diff(fechaInicio, "days")["values"]["days"] < 1) {
            this.fechasNoPermitidas.push(fechaInicio.toFormat("yyyy-MM-dd"));

            for (let i = 0; i <= minimosDiasEntreReservas; i++) {
              let fechaNo = fechaFinal.plus({ days: i });
              this.fechasNoPermitidas.push(fechaNo.toFormat("yyyy-MM-dd"));
            }
          } else {
            let diferenciaDias = Math.round(
              fechaFinal.diff(fechaInicio, "days")["values"]["days"]
            );

            for (let i = 0; i <= diferenciaDias; i++) {
              let fechaNo = fechaInicio.plus({ days: i });

              this.fechasNoPermitidas.push(fechaNo.toFormat("yyyy-MM-dd"));
            }
          }
        });

        //Limitamos tambien fechas anteriores a la fecha actual
        let diaActual = DateTime.now();

        diaActual = diaActual.plus({ days: 1 });

        this.fechaMinima = diaActual.toFormat("yyyy-MM-dd");
      } else if (requisitos.last_reservation.expiry) {
        diaMasReciente = requisitos.last_reservation.expiry.replace(" ", "T");
        diaMasReciente = DateTime.fromISO(diaMasReciente);

        //Le sumamos un el minimos de dias entre reservas.
        diaMasReciente = diaMasReciente.plus({
          days: minimosDiasEntreReservas,
        });

        let diaActual = DateTime.now();

        // Si es mayor que el dia actual lo mantenemos. Si no mostraremos el dia actual
        if (diaActual > diaMasReciente) {
          diaMasReciente = diaActual;
          diaMasReciente = diaMasReciente.plus({
            days: minimosDiasEntreReservas,
          });
        }

        diaMasReciente = diaMasReciente.plus({ minutes: 1 });

        //Actualmente tengo la fecha mas reciente para que pueda coger una reserva
        this.fechaEntrada = diaMasReciente.toFormat("yyyy-MM-dd ") + "00:00:00";

        this.fechaPermitirdaEntrada =
          diaMasReciente.toFormat("yyyy-MM-dd ") + "00:00:00";

        this.fechaMinima = diaMasReciente.toFormat("yyyy-MM-dd ");
      }
    },

    obtenerMes(fecha) {
      let fechaObtenida = fecha.replace(" ", "T");

      fechaObtenida = DateTime.fromISO(fechaObtenida, {
        zone: "Europe/Madrid",
      });

      return fechaObtenida.toFormat("MMM").toUpperCase();
    },

    obtenerDia(fecha) {
      let fechaObtenida = fecha.replace(" ", "T");

      fechaObtenida = DateTime.fromISO(fechaObtenida);

      return fechaObtenida.toFormat("dd");
    },

    esElMismoDia(array) {
      let fechaEntrada = array["activation"].replace(" ", "T");
      let fechaSalida = array["expiry"].replace(" ", "T");

      fechaEntrada = DateTime.fromISO(fechaEntrada);
      fechaSalida = DateTime.fromISO(fechaSalida);

      if (fechaSalida.diff(fechaEntrada, "days")["values"]["days"] > 1)
        return true;
      else return false;
    },

    //Elegimos fichero de imagen
    elegirFichero() {
      let fi = this.$refs["fileinput"];
      fi.$children[0].$refs.input.click();
    },
    errorImagenPerfil(e) {
      this.$cuadroDeDialogo.mostrar("Error al seleccionar imagen", e, [
        "Ok",
      ]);
    },

    obtenerFechasInicioFinalJuntos(reserva) {
      let fechaInicio = DateTime.fromISO(
        reserva["activation"].replace(" ", "T")
      );
      let fechaFinal = DateTime.fromISO(reserva["expiry"].replace(" ", "T"));

      if (fechaInicio.toFormat("dd-MM") == fechaFinal.toFormat("dd-MM")) {
        let f =
          fechaFinal.toFormat("EEEE dd/MM") +
          ", " +
          fechaInicio.toFormat("HH:mm") +
          " - " +
          fechaFinal.toFormat("HH:mm");

        return f.charAt(0).toUpperCase() + f.slice(1);
      } else {
        let f =
          fechaInicio.toFormat("EEEE dd/MM HH:mm") +
          " - " +
          fechaFinal.toFormat("EEEE dd/MM HH:mm");

        return f.charAt(0).toUpperCase() + f.slice(1);
      }
    },

    obtenerFechaInicioValidaSegunCesionesAnteriores() {
      let fechaActual = DateTime.now();
      this.itemsCesiones = [];
      if (this.requisitos.place_releases) {
        this.itemsCesiones = this.requisitos.place_releases;

        //Obtenemos el total de cesiones activas
        this.cesionesEnCurso = this.itemsCesiones.length;

        // let diaMasReciente = "";
        //Recorremos las cesiones activas para marcar las fechas ya ocupadas
        this.itemsCesiones.forEach((element) => {
          let fechaInicio = DateTime.fromISO(
            element["activation"].replace(" ", "T")
          );
          let fechaFinal = DateTime.fromISO(
            element["expiry"].replace(" ", "T")
          );

          //Comprobamos si la diferencia entre las dos fechas es menos de un dia para añadirla como fechas no permitidas
          if (fechaFinal.diff(fechaInicio, "days")["values"]["days"] < 1) {
            this.fechasNoPermitidasCesiones.push(
              fechaInicio.toFormat("yyyy-MM-dd")
            );
          } else {
            let diferenciaDias = Math.round(
              fechaFinal.diff(fechaInicio, "days")["values"]["days"]
            );

            for (let i = 0; i < diferenciaDias; i++) {
              let fechaNo = fechaInicio.plus({ days: i });

              this.fechasNoPermitidasCesiones.push(
                fechaNo.toFormat("yyyy-MM-dd")
              );
            }
          }
        });
        let fechaOficial = fechaActual.plus({ days: 1 });

        this.fechaMinimaCesiones = fechaOficial.toFormat("yyyy-MM-dd ");
        //Añadimos la fecha minima. Fecha maxima no es necesario al no tener limite de cesion

        // if (diaMasReciente > fechaActual) {
        //   let fechaFinal = diaMasReciente.plus({ days: 1 })
        //   this.fechaInicioCesion =
        //     fechaFinal.toFormat("yyyy-MM-dd ") + "00:00:00";

        //   this.fechaMinimaCesiones = fechaFinal.toFormat("yyyy-MM-dd ");
        // } else {
        //   let fechaFinal = fechaActual.plus({ days: 1 })
        //   this.fechaInicioCesion =
        //     fechaActual.toFormat("yyyy-MM-dd ") + "00:00:00";
        //   this.fechaMinimaCesiones = fechaActual.toFormat("yyyy-MM-dd ")
        // }
      } else {
        let fechaOficial = fechaActual.plus({ days: 1 });

        this.fechaMinimaCesiones = fechaOficial.toFormat("yyyy-MM-dd ");
      }
    },
  },
  created() {
    this.obtenerInformacionEntidad();
    this.editarUsuario();

    Preferences.get({key:"idioma_caja_movil"})
      .then((res)=>{
        Settings.defaultLocale = res['value'];
        i18n.locale = res['value'];
      })

    setTimeout(() => {
      this.qrActivo = true;
    }, 1000);

  },
};
</script>

<style>
.box {
  width: 100%;
  height: 100%;
}

.rounded-card {
  border-radius: 50px;
}

.btn::before {
  outline: none !important;
  box-shadow: none !important;
}
</style>